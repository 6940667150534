@font-face {
  font-family: "Nunito Sans Bold";
  src: url("../fonts/nunito-sans-bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Sans Regular";
  src: url("../fonts/nunito-sans-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Nunito Sans SemiBold";
  src: url("../fonts/nunito-sans-semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

body {
  letter-spacing: 0.3px;
  background-color: #1b2431;
  color: white;
  font-family: "Nunito Sans Regular" !important;
}

.custom-bold-font {
  font-family: "Nunito Sans Bold !important";
}

.custom-semibold-font {
  font-family: "Nunito Sans SemiBold !important";
}

.css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root {
  color: white !important;
}
.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
  color: white !important;
}
.css-z17wx0-MuiPaper-root-MuiPickersPopper-paper {
  margin-top: 5px !important;
  background-color: #18202f !important;
  color: white !important;
}
.css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper {
  margin-top: 5px !important;
  background-color: #18202f !important;
  color: white !important;
}

.css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon {
  fill: white !important;
}

.css-1vooibu-MuiSvgIcon-root {
  fill: white !important;
}

.css-23p0if-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  border: 1px solid rgb(135 134 134 / 60%) !important;
  color: white !important;
}

.css-1m6xyh-multiValue {
  background-color: #475469 !important;
}

.highcharts-legend-item > text {
  fill: white !important;
}

.css-1sukkk7-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 40px !important;
}

.css-eqpfi5-MuiAccordionSummary-content.Mui-expanded {
  margin: 0px !important;
}

.css-1sukkk7-MuiButtonBase-root-MuiAccordionSummary-root {
  height: 8% !important;
}

table {
  caption-side: bottom !important;
  border-collapse: initial !important;
  border-spacing: 0px !important;
}

/* Header */

.custom-fa-style[data-count]:after {
  position: absolute;
  right: -21%;
  top: -4%;
  content: attr(data-count);
  font-size: 9px;
  border-radius: 999px;
  line-height: 0.75em;
  color: white;
  background: rgba(255, 0, 0, 0.85);
  text-align: center;
  min-width: 18px;
  min-height: 15px;
  font-weight: bold;
  padding-top: 3px;
}
.custom-fa-style {
  position: relative;
  display: inline-block;
  /* width: 2em; */
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
::placeholder {
  color: #b6b6b6 !important;
}
.cus-input-box::placeholder {
  color: #b6b6b6 !important;
  fill: #b6b6b6 !important;
  opacity: 1 !important;
}

.modal-open::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  backdrop-filter: blur(8px);
  z-index: 1040;
}

.modal-dialog {
  z-index: 1050;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}
select:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}
select {
  color: white;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  padding-right: 25px;
}

/* menu toggle */

.menu-toggle {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-toggle::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 4px;
  background: #000;
  box-shadow: 0 8px 0 #000, 0 -8px 0 #000;
}

/* profile menu */

.profile {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  text-align: end;
}

.profile h3 {
  text-align: end;
  line-height: 1;
  margin-bottom: 4px;
  font-weight: 600;
}

.profile p {
  line-height: 1;
  font-size: 14px;
  opacity: 0.6;
}

.profile .img-box {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.profile .img-box img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.retrie-table > .table-responsive {
  border: 1px solid #313d4f;
  border-radius: 12px;
}

/* menu (the right one) */
.menu {
  position: absolute;
  top: 50px;
  right: 11px;
  width: 150px;
  border-radius: 4px;
  min-height: 90px;
  background: #273142;
  border: 1px solid #313d4f;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  z-index: 1000;
  transition: 300ms;
}
.menu::before {
  content: "";
  position: absolute;
  top: -6px;
  right: 5px;
  width: 16px;
  height: 20px;
  border: 1px solid #313d4f;
  background: #273142;
  transform: rotate(45deg);
  z-index: -1;
}

.menu.active {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

/* menu links */

.menu ul {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  background: #273142;
  color: white !important;
  padding: 0px;
}

.menu ul li {
  list-style: none;
}

.menu ul li a {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  gap: 6px;
}

.menu ul li a i {
  font-size: 1.2em;
}

/* Sidebar  */

.css-cveggr-MuiListItemIcon-root {
  min-width: 43px !important;
}

.custom-sidebar-list {
  background-color: #00abff !important;
  border-radius: 5px 0px 0px 5px !important;
}
.custom-sidebar-list-2:hover {
  background-color: #48576c !important;
  border-radius: 5px 0px 0px 5px !important;
}
.custom-closed-sidebar > li:hover {
  background-color: #48576c !important;
}

/* Wallet Page  */

.highcharts-credits {
  display: none !important;
}

.frame-107-uil-arrow-up-13 {
  height: 35px;
  width: 34px;
}

.uil-arrow-up-4-uil-arrow-up-7 {
  height: 35px;
  width: 34px;
}

.css-1kdkav6-control {
  border-color: #273142 !important;
}

.btn-close {
  --bs-btn-close-bg: url('data:image/svg+xml,<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.22461 21.1222L21.5046 3.84221" stroke="white" stroke-width="3.6" stroke-linecap="round" stroke-linejoin="round"/><path d="M4.22461 3.84221L21.5046 21.1222" stroke="white" stroke-width="3.6" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
}

.modal-header {
  border: none !important;
}

.modal-footer {
  border: none !important;
}

.modal-content {
  border-radius: 12px !important;
  border: 1px solid #313d4f;
}

.rdw-editor-wrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
}

.rdw-editor-toolbar {
  border: 1px solid #1b2431 !important;
  background: #1b2431 !important;
}

/* Stepper */

.step-container {
  width: 100%;
}

.step {
  padding: 10px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  background-color: cream;
}

.v-stepper {
  position: relative;
  /*   visibility: visible; */
}

/* regular step */
.step .circle {
  background-color: transparent;
  border: 3px solid gray;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step .line {
  top: 29px;
  left: 14px;
  height: 82%;
  position: absolute;
  border-left: 3px solid gray;
}

.step.completed .circle {
  visibility: visible;
  background-color: rgb(6, 150, 215);
  border-color: rgb(6, 150, 215);
}

.step.completed .line {
  border-left: 3px solid rgb(6, 150, 215);
}

.step.active .circle {
  visibility: visible;
  border-color: rgb(6, 150, 215);
}

.step.empty .circle {
  visibility: hidden;
}

.step.empty .line {
  /*     visibility: hidden; */
  /*   height: 150%; */
  top: 0;
  height: 150%;
}

.step:last-child .line {
  display: none;
}

.step-content {
  margin-left: 20px;
  display: inline-block;
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  color: rgb(255 255 255) !important;
}

.modal-popup-retry {
  cursor: pointer; /* Change cursor to pointer */
  pointer-events: auto; /* Allow it to receive click events */
  text-align: center;
  color: #00e7ff;
}

/* File Upload Btn  */

.custom-upload-file {
  border-radius: 0.375rem 0rem 0rem 0.375rem !important;
}

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file > input[type="file"] {
  display: none;
}
.file > label {
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
  outline: 0;
  -webkit-user-select: none;
  user-select: none;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border-style: solid;
  border-width: 1px;
  color: hsl(0, 0%, 29%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  border-radius: 0px 9px 9px 0px;
  background-color: #178d97;
}

.file > label:hover {
  border-color: hsl(0, 0%, 21%);
}

.file > label:active {
  background-color: #1b2431;
}

.file > label > i {
  padding-right: 5px;
}

.file--upload > label {
  color: hsl(204deg 20.71% 94.23%);
  border-color: hsl(204, 86%, 53%);
}

.file--upload > label:hover {
  border-color: #1b2431;
  background-color: #1b2431;
}

.file--upload > label:active {
  background-color: hsl(204, 86%, 91%);
}

.file--uploading > label {
  color: hsl(48, 100%, 67%);
  border-color: hsl(48, 100%, 67%);
}

.file--uploading > label > i {
  animation: pulse 5s infinite;
}

.file--uploading > label:hover {
  border-color: hsl(48, 100%, 67%);
  background-color: #1b2431;
}

.file--uploading > label:active {
  background-color: #1b2431;
}

@keyframes pulse {
  0% {
    color: hsl(48, 100%, 67%);
  }
  50% {
    color: hsl(48, 100%, 38%);
  }
  100% {
    color: hsl(48, 100%, 67%);
  }
}

/* scroll bar Design */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #304057;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #272727;
}

/* Mobile Navbar */

body {
  padding: 10px;
  padding-bottom: 50px;
}
h1 {
  margin-bottom: 2000px;
}
.mobile-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  will-change: transform;
  transform: translateZ(0);
  display: flex;
  height: 68px;
  box-shadow: 0 -2px 5px -2px #333;
  background-color: #212a3a;
  padding: 5px;
}
.mobile-bottom-nav__item {
  text-align: center;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
}
.mobile-bottom-nav__item--fixed {
  padding: 6px 12px 6px 12px;
  background: #323d4e;
  border-radius: 100%;
  width: -moz-fit-content;
  width: fit-content;
  border: 2px solid #535f71;
}

.mobile-bottom-nav__item--active {
  border-bottom: 4px solid #00abff;
}

.mobile-bottom-nav__item-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

/* Admin Table  */
.admin-table,
.admin-table-2 {
  width: 100%;
  margin: 0px auto 20px auto;
  /* background-color: #273142; */
  color: #fff;
  /* padding: 20px; */
  border-radius: 4px;
  max-height: 100%; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: auto; /* Enable horizontal scrolling */
  /* box-shadow: 0 0 6px #666666; */
}
.admin-table > table,
.admin-table-2 > table {
  overflow: scroll !important;
  /* border: 2px solid #313c4d; */
  border-radius: 5px;
  width: 100%; /* Ensure the table takes full width */
}
.admin-table table,
.admin-table-2 table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  overflow: scroll !important;
  line-height: 150%;
}
.admin-table table th,
.admin-table-2 table th,
.admin-table table .expand,
.admin-table-2 table .expand {
  color: #ffffff;
  padding: 10px;
  font-weight: 500;
  background-color: #313c4d;
}
.admin-table table .expand,
.admin-table-2 table .expand {
  cursor: pointer;
  min-width: 20px !important;
}
.admin-table table .open,
.admin-table-2 table .open {
  display: table;
}
.admin-table table .close,
.admin-table-2 table .close {
  display: none;
}
.admin-table table .close,
.admin-table-2 table .close {
  display: none;
}

.css-1kdkav6-control {
  height: 47px !important;
}

.custom-flatpickr {
  background-color: #1b2431;
  color: white;
  border: 1px solid #3a4b5e;
  border-radius: 4px;
  padding: 8px;
}

.flatpickr-calendar {
  background-color: #273142;
  color: white;
  box-shadow: 0 3px 13px rgba(0, 0, 0, 0.3);
}

.flatpickr-day {
  color: white;
}

.flatpickr-day.selected {
  background-color: #3a4b5e;
  border-color: #3a4b5e;
}

.flatpickr-day:hover {
  background-color: #3a4b5e;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  fill: white;
  padding: 10px;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #3a4b5e;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background-color: #273142;
  color: white;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background-color: #3a4b5e;
}

.custom-trade-detail-header > .modal-title {
  width: 100%;
}

.login-screen {
  background-image: url("../../assets/images/login-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

body {
  padding: 0px !important;
}

.cus-input-box {
  background-color: #323d4e !important;
  color: white !important;
  border: 1px solid #313d4f;
}

.table-responsive table th {
  font-weight: 500;
}

/* Override autofill styles */
.cus-input-box:-webkit-autofill,
.cus-input-box:-webkit-autofill:hover,
.cus-input-box:-webkit-autofill:focus,
.cus-input-box:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #323d4e inset !important;
  -webkit-text-fill-color: white !important;
  transition: background-color 5000s ease-in-out 0s;
}

/* For Firefox */
.cus-input-box:autofill {
  background-color: #323d4e !important;
  color: white !important;
}

.stepper-horizontal {
  display: table;
  width: 100%;
  margin: 0 auto;
}
.stepper-horizontal .step {
  display: table-cell;
  position: relative;
  padding: 1.5rem;
  z-index: 2;
  width: 25%;
}
.stepper-horizontal .step:last-child .step-bar-left,
.stepper-horizontal .step:last-child .step-bar-right {
  display: none;
}
.stepper-horizontal .step .step-circle {
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  border-radius: 50%;
  text-align: center;
  line-height: 1.75rem;
  font-size: 1rem;
  font-weight: 600;
  z-index: 2;
  border: 2px solid #d9e2ec;
}
.stepper-horizontal .step.done .step-circle {
  background-color: #00abff;
  border: 2px solid #00abff;
  color: #fff;
}
.stepper-horizontal .step.done .step-circle:before {
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f00c";
}
.stepper-horizontal .step.done .step-circle * {
  display: none;
}
.stepper-horizontal .step.done .step-title {
  color: #102a43;
}
.stepper-horizontal .step.editing .step-circle {
  background: transparent;
  border-color: #fff;
  color: #fff;
}
.stepper-horizontal .step.editing .step-title {
  color: #fff;
}
.stepper-horizontal .step .step-title {
  /* margin-top: 1rem; */
  font-size: 1rem;
  font-weight: 600;
}
.stepper-horizontal .step .step-title,
.stepper-horizontal .step .step-optional {
  text-align: center;
  color: #829ab1;
}
.stepper-horizontal .step .step-optional {
  font-size: 0.75rem;
  font-style: italic;
  color: #9fb3c8;
}
.stepper-horizontal .step .step-bar-left,
.stepper-horizontal .step .step-bar-right {
  position: absolute;
  top: calc(2rem + 5px);
  height: 5px;
  background-color: #d9e2ec;
  border: solid #d9e2ec;
  border-width: 2px 0;
}
.stepper-horizontal .step .step-bar-left {
  width: calc(100% - 2rem);
  left: 50%;
  margin-left: 1rem;
  z-index: -1;
}
.stepper-horizontal .step .step-bar-right {
  width: 0;
  left: 50%;
  margin-left: 1rem;
  z-index: -1;
  transition: width 500ms ease-in-out;
}
.stepper-horizontal .step.done .step-bar-right {
  background-color: #00abff;
  border-color: #00abff;
  z-index: 3;
  width: calc(100% - 2rem);
}

.stepper-horizontal .step.rejected .step-circle:before {
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f00d";
}

.stepper-horizontal .step.rejected .step-circle {
  background-color: #cb1b2c;
  border: 2px solid #cb1b2c;
  color: #fff;
}

.accordion-button {
  background-color: #273142;
  color: white;
  border: none;
  border-radius: 12px !important;
}

@media (min-width: 1200px) {
  .custom-trade-detail-modal {
    --bs-modal-width: 1280px !important;
  }
}

@media (min-width: 992px) {
  .custom-trade-detail-modal {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .custom-trade-detail-modal {
    --bs-modal-width: 700px;
  }
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
  background: rgba(0, 132, 255, 0.473);
  /* font-family: Arial, Helvetica, sans-serif; */
  /* text-align: center; */
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle::after {
  border-right: 2px solid rgb(255 251 251 / 40%) !important;
  border-bottom: 2px solid rgb(255 255 255 / 40%) !important;
}
